const IDN = {
  //COMMON
  HOME: 'Beranda',
  BROADCAST_SCHEDULE: 'Pertandingan',
  LEADERBOARD: 'Peringkat',
  NEWS: 'Berita',
  CONTACT: 'Kontak',
  LOGIN: 'Masuk',
  CLOSE: 'Tutup',
  FIGHT_WEEK: 'PEKAN PERTANDINGAN ',
  LIVE_NOW: ' LIVE SEKARANG',
  CONTINUE: 'Lanjutkan',
  USER_NAME: 'User Name',
  HOURS: 'jam',
  MIN: 'menit',
  SIGN_OUT: 'KELUAR',
  //HOME
  HOME_TITLE: 'PREDIKSI PERTANDINGAN <br> DAN RAIH KEMENANGAN BESAR',
  HOME_TAGLINE:
    'Tebak hasil pertarungan dan raih kesempatan <br> untuk memenangkan hadiah utama setiap minggu.',
  HOME_BUTTON: 'Prediksi Sekarang',
  //BROADCAST
  BROADCAST_NOTES: 'Please, note that Prelims are in separate Tabs',
  BROADCAST_NOTES_MORE_EVENTS: 'Harap pilih Acara terlebih dahulu.',
  BROADCAST_MAIN_CARD: 'Main Card',
  BROADCAST_PRELIMS: 'Prelims',
  BROADCAST_EARLY: 'Early Prelims',
  BROADCAST_MAIN_EVENT: 'Main Event',
  BROADCAST_POINTS_INFO: 'Info Poin',
  BROADCAST_FIGHTER_RECORD: 'Data Petarung',
  BROADCAST_POINTS_ALLOCATION: 'Penetapan Poin untuk Acara Ini',
  BROADCAST_CORRECT_OUTCOME: 'Keluaran yang Benar (Menang, Seri): ',
  BROADCAST_CORRECT_METHOD: 'Metode yang Benar (KO/TKO dll.): ',
  BROADCAST_CORRECT_METHOD_SUBMISSION: 'Metode yang Benar Pengajuan: ',
  BROADCAST_CORRECT_METHOD_DECISION: 'Correct Method Keputusan: ',
  BROADCAST_BONUS: 'Bonus atas Hasil yang Benar',
  BROADCAST_CORRECT_ROUND: 'Jumlah Putaran yang Benar: ',
  BROADCAST_MULTIPLIER_MAIN: 'Pengali Poin Main Card: ',
  BROADCAST_MULTIPLIER_PRELIMS: 'Pengali Poin Prelims: ',
  BROADCAST_MULTIPLIER_FEATURED: 'Pengali Poin Featured: ',
  BROADCAST_404: 'Belum ada prediksi',
  BROADCAST_COUNTDOWN: 'Permintaan prediksi akan dibuka pada: ',
  BROADCAST_CLOSE_ON: 'Permintaan prediksi akan ditutup pada:',
  BROADCAST_AVAILABLE_SOON:
    'Acara Telah Berakhir: Hasil Leaderboard akan segera tersedia.',
  BROADCAST_END:
    'Acara Telah Berakhir: Hasil kemenangan dapat dilihat di Leaderboard.',
  BROADCAST_HOW: 'Bagaimana?',
  BROADCAST_WHICH_ROUND: 'Ronde Berapa?',
  BROADCAST_CANCELLED: 'Pertandingan dibatalkan!',
  BROADCAST_LOGIN: 'Login untuk membuat prediksi',
  BROADCAST_EVENT_404: 'Saat ini tidak ada Pertandingan yang berlangsung.',
  BROADCAST_STAY_TUNED: 'Pantau terus!',
  FIGHTER_PROFILE: 'Profile Petarung',
  FIGHTER_SEE_MORE: 'Lihat lebih banyak detail tentang petarung ini di sini',
  //RECAP
  RECAP_MON_RANK: 'Montlhy Rank',
  RECAP_MON_POINT: 'Monthly Points',
  RECAP_WEEK_RANK: 'Weekly Rank',
  RECAP_WEEK_POINT: 'Weekly Points',
  //AUTH
  AUTH_LOGIN: 'Masuk ke Mola - Fight Predictor',
  AUTH_EMAIL: 'Email',
  AUTH_PASSWORD: 'Kata Sandi',
  AUTH_ALREADY_HAVE_ACCOUNT: 'Sudah punya akun? Masuk dengan kata sandi.',
  AUTH_BUTTON: 'Masuk / Daftar',
  AUTH_CONTINUE_WITH_GOOGLE: 'Lanjutkan dengan Google',
  AUTH_CONFIRM_EMAIL: 'Silakan cek email Anda!!',
  AUTH_CONFIRM_EMAIL_2: 'Tautan konfirmasi sudah dikirim ke email Anda!',
  AUTH_EMAIL_VALIDATION: 'Email harus valid',
  AUTH_ONE_STEP_AHEAD: 'Satu Langkah Lebih Maju',
  AUTH_INVALID_EMAIL: 'Tautan email tidak valid!',
  AUTH_WELCOME_BACK:
    '"Selamat Datang Kembali! Mengalihkan Anda ke halaman beranda dalam beberapa detik ......"',
  //LEADERBOARD
  LEADERBOARD_MONTHLY_LEADERBOARD: 'PERINGKAT BULANAN',
  LEADERBOARD_WEEKLY_LEADERBOARD: 'PERINGKAT MINGGUAN',
  LEADERBOARD_WEEKLY_LEADERBOARD_FUN: 'PERINGKAT MINGGUAN FUN',
  LEADERBOARD_MONTHLY_REWARD: 'PERINGKAT REWARD BULANAN',
  LEADERBOARD_WEEKLY_REWARD: 'PERINGKAT REWARD MINGGUAN',
  LEADERBOARD_MONTHLY: 'Bulanan',
  LEADERBOARD_WEEKLY: 'Mingguan',
  LEADERBOARD_MONTHLY_FUN: 'Bulanan Fun',
  LEADERBOARD_WEEKLY_FUN: 'Mingguan Fun',
  LEADERBOARD_REWARD_MONTHLY: 'Bulanan Reward',
  LEADERBOARD_REWARD_WEEKLY: 'Mingguan Reward',
  LEADERBOARD_RANK: 'Ranking',
  LEADERBOARD_TOTAL_POINTS: 'Jumlah Poin',
  LEADERBOARD_TOTAL_MOLA_POINTS: 'Jumlah Poin Mola',
  LEADERBOARD_POINTS: 'Poin',
  LEADERBOARD_CORRECT_OUTCOME: 'Hasil Benar',
  LEADERBOARD_CORRECT_METHOD: 'Metode Benar',
  LEADERBOARD_CORRECT_ROUNDS: 'Ronde Benar',
  //PREDICTION
  PREDICTION: ' Prediksi',
  PREDICTION_GAME: 'Permainan',
  PREDICTION_OUTCOME: 'Hasil',
  PREDICTION_METHOD: 'Metode',
  PREDICTION_ROUNDS: 'Ronde',
  PREDICTION_SUBMISSION: 'Pengajuan (Submission)',
  PREDICTION_DECISION: 'Keputusan',
  PREDICTION_LOSES: 'Kalah',
  PREDICTION_WIN: 'Menang',
  PREDICTION_DRAW: 'Seri',
  WHICH_ROUND: 'Ronde Berapa?',
  SUBMIT_PREDICTION: 'Masukkan Prediksi',
  PREDICTION_CLOSE_IN: 'Prediksi akan ditutup pada ',
  IN_X_DAYS: ' dalam {x} hari',
  //CONTACT
  CONTACT_US: 'Hubungi Kami',
  CONTACT_MESSAGE:
    'Jangan ragu-ragu untuk menghubungi kami jika Anda memerlukan informasi lebih lanjut atau memiliki masalah lain.',
  CONTACT_BUTTON: 'Kirim kami beberapa email',
  //NEWS
  NEWS_MFP: 'Berita Mola Fight Club',
  NEWS_VIEWS: ' Melihat',
  NEWS_VIEW: ' Melihat',
  NEWS_SHARE: 'Bagikan',
  NEWS_DAYS_AGO: ' Days Ago',
  //MY ACCOUNT HOME
  MY_ACCOUNT: 'Akun Saya',
  MY_ACCOUNT_WELCOME: 'Selamat Datang',
  MY_ACCOUNT_MOLA_POINTS: 'Poin Mola',
  MY_ACCOUNT_POINTS_WON: 'Poin Dimenangan',
  MY_ACCOUNT_SWAP: 'Permintaan Penukaran',
  MY_ACCOUNT_PROFILE: 'Profil Saya',
  //PROFILE
  MY_PROFILE: 'Profil Saya',
  MY_PROFILE_ACCOUNT_VERIFICATION: 'Verifikasi Akun',
  MY_PROFILE_ACCOUNT_EMAIL: 'Email Akun',
  MY_PROFILE_PASSWORD: 'Kata Sandi',
  MY_PROFILE_ACCOUNT_INFORMATION: 'Informasi Akun',
  MY_PROFILE_ACCOUNT_TYPE: 'Tipe Akun',
  MY_PROFILE_ACCOUNT_STATUS: 'Status Akun',
  MY_PROFILE_MOLA_SUBSCRIPTION: 'Status Langganan Mola',
  MY_PROFILE_ACTIVE: 'Aktif',
  MY_PROFILE_NOT_MOLA_SUBSCRIBER: 'Bukan Pelanggan Mola',
  MY_PROFILE_FREE: 'Gratis',
  MY_PROFILE_PERSONAL_INFORMATION: 'Informasi Pribadi',
  MY_PROFILE_DISPLAY_NAME: 'Nama Tampilan',
  MY_PROFILE_ABOUT_ME: 'Tentang Saya',
  MY_PROFILE_FULL_LEGAL_NAME: 'Nama Lengkap',
  MY_PROFILE_DOB: 'Tanggal Lahir',
  MY_PROFILE_GENDER: 'Jenis Kelamin',
  MY_PROFILE_ADDRESS_DETAILS: 'Detail Alamat',
  MY_PROFILE_LANGUAGE: 'Bahasa',
  MY_PROFILE_CURRENT_VERSION: 'Versi Saat Ini',
  MY_PROFILE_TANDC: 'Syarat & Ketentuan',
  MY_PROFILE_PRIVACY_POLICY: 'Kebijakan Privasi',
  MY_PROFILE_UPGRADE_NOW: `Langganan Sekarang`,
  MY_PROFILE_UPGRADE_NOW_MOBILE: `Langganan <br /> Sekarang`,
  MY_PROFILE_SUBSCRIBE_NOW: 'Berlangganan Sekarang',
  MY_PROFILE_SUBSCRIBE_NOW_MOBILE: 'Berlangganan <br /> Sekarang',
  MY_PROFILE_SIGN_OUT: 'Keluar dari Akun Anda',
  MY_PROFILE_BUTTON_CHANGE: 'Ubah',
  MY_PROFILE_ABOUT: '',
  MY_PROFILE_DOB_TEXT: 'Masukkan tanggal lahir anda',
  MY_PROFILE_GENDER_NOT_SET: 'BELUM DITENTUKAN',
  MY_PROFILE_GENDER_M: 'LAKI - LAKI',
  MY_PROFILE_GENDER_F: 'PEREMPUAN',
  MY_PROFILE_GENDER_O: 'LAINNYA',
  MY_PROFILE_PASSWORD_SET: 'Tolong, atur password anda',
  MY_PROFILE_NEW_EMAIL: 'Tolong atur email anda',
  MY_PROFILE_CHECK_EMAIL: '',
  MY_PROFILE_EMAIL_INSTRUCTIONS: '',
  MY_PROFILE_BUT_CHANGE_EMAIL: 'Ubah Email',
  MY_PROFILE_ACC_NOT_VERIFIED: '',
  MY_PROFILE_ACC_VERIFIED: '',
  MY_PROFILE_ACC_BTN_VERIFY: '',
  MY_PROFILE_ACC_VERIFY: '',
  ACC_EMAIL_NOT_SET: 'Your email adress has not been set',
  ACC_EMAIL_ASS:
    'We have assigned you a temporary email address that is used for auhtentication together with your connected wallet.',
  // SETTINGS UPDATED
  SETTINGS_UPDATED_DISPLAYNAME: 'Nama Tampilan Diperbarui',
  SETTINGS_UPDATED_ABOUTME: 'Tentang Saya Diperbarui',
  SETTINGS_UPDATED_FULLNAME: 'Nama Lengkap Diperbarui',
  SETTINGS_UPDATED_DOB: 'Tanggal Lahir Diperbarui',
  SETTINGS_UPDATED_GENDER: 'Gender Diperbarui',
  SETTINGS_UPDATED_ADDRESS: 'Alamat Diperbarui',
  SETTINGS_UPDATED_LANGUAGE: 'Bahasa diperbarui',
  SETTINGS_UPDATED_PHOTO: 'Foto Profil Diperbarui',
  //POINTS
  POINTS_NON_MOLA:
    'Karena Anda adalah pelanggan Mola gratis, poin di bawah ini adalah poin simulasi, yang bisa didapatkan jika Anda adalah pelanggan Mola berbayar',
  POINTS_FOR: 'Points Dimenangkan bagi',
  POINTS_404:
    'Tidak ada hasil atau poin yang dimenangkan untuk pertarungan acara ini',
  POINTS_MORE: 'Muat Lebih Banyak dari Acara Masa Lalu',
  POINTS_HISTORY: 'Points Won History',
  POINTS_HISTORY_404: 'No history of points won found',
  //ADMIN
  ADMIN_DASHBOARD: 'Admin Dashboard',
  GAME_MANAGEMENT: 'Game Management',
  ADMIN_MANAGEMENT: 'Admin Tasks',
  EVENTS_AND_GAMES: 'Events & Games',
  GAME_RESULTS: 'Game Results',
  CREATE_EVENT: 'Create Event',
  NEWS_MANAGEMENT: 'News Management',
  POINTS_AND_TOKEN_MANAGEMENT: 'Points & Token Management',
  POINTS_MANAGEMENT: 'Points Management',
  SWAP_REQUEST: 'Swap Request',
  MANAGE_EVENTS_AND_GAMES: 'Manage Events and Games',
  PROCESS_GAME_RESULTS: 'Process Game Results',
  CREATE_NEW_PREDICTION_GAME:
    'Create a New Prediction Game for Event and Fights',
  MANAGE_NEWS: 'Manage News',
  //DASHBOARD
  ADMIN_TOTAL_SIGN_UPS: 'Total Sign Ups',
  ADMIN_TOTAL_SIGN_UPS_TODAY: 'Total Sign Ups Today',
  ADMIN_TOTAL_SIGN_UPS_THIS_MONTH: 'Total Sign Ups This Month',
  ADMIN_TOTAL_MOLA_SUBSCRIBERS: 'Total Mola Subscribers',
  ADMIN_TOTAL_PREDICTIONS: 'Total Predictions',
  ADMIN_TOTAL_POINTS_WON: 'Total Points Won',
  //FOOTER
  FOOTER_TITLE: 'PREDIKSI PERTANDINGAN DAN RAIH KEMENANGAN BESAR',
  FOOTER_TITLE_NEW: 'BERLANGGANAN MOLA',
  MOLA_FIGHT_CLUB: 'Mola Digital - Katalog',
  ABOUT: 'Tentang Mola Digital',
  SOCIAL: 'Jejaring Sosial',
  //Register
  REGISTER_WELCOME_TO_MFP: 'Selamat datang di Mola - Fight Predictor',
  REGISTER_LOGGED: 'Anda sudah masuk!',
  REGISTER_CONTINUE: 'Lanjutkan untuk menyelesaikan pembuatan akun Anda',
  REGISTER_COMPLETE: 'Lengkapi Akun Anda',
  REGISTER_IN_ORDER:
    'Untuk menyelesaikan pengaturan akun Anda, kami membutuhkan informasi sedikit lebih dari Anda',
  REGISTER_FULL_NAME: 'Nama Lengkap',
  REGISTER_PASSWORD: 'Kaat Sandi',
  REGISTER_SUBMIT: 'Kirim',
  REGISTER_WELCOME: 'Selamat Datang',
  REGISTER_WELCOME_BACK: 'Selamat Datang Kembali',
  REGISTER_THANKS:
    'Terima kasih telah bergabung bersama kami Anda akan dialihkan ke halaman beranda....',
  REGISTER_REDIRECT:
    'Mengalihkan anda ke halaman beranda dalam beberapa detik...',
  //Authorise
  LOADING: 'Loading...',
  ERROR: 'Error...',
  PLEASE_WAIT: 'Mohon tunggu beberapa saat...',
  BACK_TO_HOME: 'Kembali ke Beranda',
  //
  PRODUCT_DESCRIPTION: 'Deskripsi Produk',
  REDEEM_NOW: 'Menukarkan',
  QTY_LEFT: 'Jumlah Tersisa:',
  AVAILABLE: 'Stok',
  ORDER_STATUS: 'Status Pesanan',
  NO_ORDERS_MESSAGE: 'Tidak ada status pesanan',
  REDEEM_HISTORY: 'Riwayat Penukaran',
  CATEGORY: 'Kategori',
  QTY: 'Jumlah',
  REQUESTED: 'Diminta',
  REJECTED: 'Ditolak',
  COMPLETED: 'Selesai',
  PROCESSED: 'Diproses',
  PENDING: 'Tertunda',
  COMPLETE: 'Selesai',
  ITEM: 'Barang',
  TYPE: 'Tipe',
  POINTS: 'Poin',
  DATE: 'Tanggal',
  PRICE: 'Harga',
  TOTAL: 'Total',
  MY_ITEM: 'Barang Saya',
  EMPTY_REDEEM_HISTORY: 'Riwayat Penukaran kosong!',
  ORDER_ID: 'ID Pesanan',
  CANCELLED: 'Dibatalkan',
  INVALID: 'Tidak Valid',
  ON_DELIVERY: 'Sedang Dikirim',
  NO_POINT_FOUND: 'Tidak Ditemukan Riwayat Poin',
  STATUS: 'Status',
  WELCOME: 'Selamat Datang',
  CATALOG: 'Katalog',
  SEARCH: 'Cari',
  MY_ITEMS: 'Barang Saya',
  REDEEM_FORM: 'Form Penukaran',
  PRODUCT_NAME: 'Nama Produk',
  PRODUCT_PRICE: 'Harga Produk',
  USERNAME: 'Nama Pengguna',
  SINCE_THIS_IS_A_PHYSICAL_ITEM_PLEASE_PROVIDE_THE_SHIPPING_ADDRESS: 'Karena ini adalah barang fisik, harap berikan alamat pengiriman.',
  SHIPPING_DETAILS: 'Detail Pengiriman:',
  RECEIVER_NAME_IF_DIFFERENT_FROM_ACCOUNT_NAME: 'Nama Penerima jika berbeda dari nama akun',
  ADDRESS_LINE_1: 'Alamat Baris 1',
  ADDRESS_LINE_2: 'Alamat Baris 2',
  CITY: 'Kota',
  POSTAL_CODE: 'Kode Pos',
  COUNTRY: 'Negara',
  PHONE_NUMBER: 'Nomor Telepon',
  SUBMIT_MY_ORDER: 'Kirim Pesanan Saya',
  ORDER_HAS_BEEN_SUBMITTED_SUCCESSFULLY: 'Pesanan telah berhasil dikirim!',
  PRODUCT_TYPE: 'Tipe Produk',
  NEW_ARRIVALS: 'Produk Terbaru',
  POPULAR_ITEMS: 'Produk Populer',
  PHYSICAL: 'Fisik',
  DIGITAL: 'Digital',
  SORT_BY: 'Urutkan berdasarkan',
  FILTER_BY_POINTS: 'Filter berdasarkan Poin',
  SORT: 'Urutkan',
  MINIMUM: 'Min',
  MAXIMUM: 'Maks',
  MOLA_POINTS_LOW_TO_HIGH: 'Poin Mola: Rendah ke Tinggi',
  MOLA_POINTS_HIGH_TO_LOW: 'Poin Mola: Tinggi ke Rendah',
  NEWEST: 'Terbaru',
  OLDEST: 'Terlama',
  CLEAR_FILTER: 'Hapus Filter',
  BACK_TO_MFP: 'Kembali ke Mola Fight Club',
  MEMBER_SINCE: 'Anggota Sejak',
  PROFILE_SCORE: 'Skor Profil',
  VERIFIED: 'Terverifikasi',
  NOT_VERIFIED: 'Tidak Terverifikasi',
  VERIFY: 'Verifikasi',
  IMA: 'Saya ',
  STARTER: 'Pemula',
  ACTIVE: 'Aktif',
  INACTIVE: 'Tidak Aktif',
  SUSPENDED: 'Ditangguhkan',
  SECONDS_AGO: ' detik yang lalu',
  MINUTES_AGO: ' menit yang lalu',
  HOURS_AGO: ' jam yang lalu',
  DAYS_AGO: ' hari yang lalu',
  MONTHS_AGO: ' bulan yang lalu',
  YEARS_AGO: ' tahun yang lalu',
  BIRTHDAY: 'Tanggal Lahir',
  LEGAL_NAME_TEXT: 'Tambahkan Nama Lengkap Resmi Anda',
  PRODUCT_404: 'Data Tidak Ditemukan',

  EMPTY: `{variable-name} tidak boleh kosong`,
  MINIMUM_LENGTH: `Panjang minimum {variable-name} adalah {number}`,

  CHANGE_YOUR_PASSWORD: 'Ubah Kata Sandi Anda',
  NEW_PASSWORD: 'Kata Sandi Baru',
  CURRENT_PASSWORD: 'Kata Sandi Saat Ini',
  SET_NEW_PASSWORD: 'Atur Kata Sandi Baru',
  CLICK_TO_SELECT_A_PHOTO: 'Klik untuk memilih foto',
  CHANGE_PHOTO: 'Ubah Foto',
  REMOVE_PHOTO: 'Hapus Foto',
  SAVE: 'Simpan',
  SET_PICTURE: 'Atur Gambar',
  TAP_TO_SELECT_PHOTO: 'Ketuk di sini untuk memilih foto <br>dari galeri Anda',

  APPLY: 'Terapkan',
  FILTER: 'Filter',
  COMING_SOON: 'Segera Datang',
  REWARD: 'Hadiah',
  ROWS_PER_PAGE: 'Baris per halaman', 
  ROWS_PER_PAGE_OF: 'dari',
  ROWS_PER_PAGE_ALL: 'Semua',

  MANAGE_ORDER: 'Kelola Pesanan',
  ORDER_MANAGEMENT: 'Manajemen Pesanan',
  MANAGE_CATALOG: 'Kelola Katalog',
  TRACKING_DASHBOARD: 'Dasbor Pelacakan',
  ADMIN_TASK: 'Tugas Admin',
  SIDE_MENU_FAVORITES: 'Atur Menu Samping',
  MANAGE_SIDE_MENU_TITLE: 'Kelola Barang Populer dan Barang Baru di Menu Samping',
  EMPTY_LIST: 'Daftar ini kosong',
  ADD: 'Tambah',
  DOWNLOAD_EXCEL: 'Unduh Excel',
  UPDATE_ORDER_DETAIL: 'Perbarui Detail Pesanan',
  ORDER_DETAIL: 'Detail Pesanan',
  ACTION: 'Aksi',
  SHOW_PUBLISHED_ITEM: 'Tampilkan Item yang Dipublikasikan Saja',
  CATEGORY_NAME: 'Nama Kategori',
  LANGUAGE_CONFIGURATION: 'Konfigurasi Bahasa',
  SHOW_ALL_STATUS: 'Tampilkan Semua Status',
  DRAFT: 'Draf',
  APPROVED: 'Disetujui',
  PUBLISHED: 'Diterbitkan',
  PAUSED: 'Ditunda',
  EXPIRED: 'Kedaluwarsa',
  SHOW_ALL_CATEGORIES: 'Tampilkan Semua Kategori',
  TYPE_TO_SEARCH_OR_FILTER: 'Ketik untuk Mencari atau Menyaring',
  LOAD_MORE: 'Muat Lebih Banyak',
  SHOWING_LAST_ITEMS: 'Menampilkan {x} item terakhir',
  EXPIRES: 'Kedaluwarsa',
  AVAILABLES: 'Tersedia',
  TOTAL_ITEM: 'Total Katalog',
  TOTAL_ORDER: 'Total Pesanan',
  TOTAL_POINT_SPEND: 'Total Poin yang Dihabiskan',
  TOTAL_USERS: 'Total Pengguna',
  TOTAL_COMPLETED_ORDER: 'Total Pesanan yang Selesai',
  TOTAL_CATEGORY: 'Total Kategori',
  REDEMPTION_RATE: 'Tingkat Penebusan',
  REMAINING_STOCK: 'Stok Tersisa',
  ADD_TO_POPULAR_LIST: 'Tambahkan ke Daftar Populer',
  SELECT_OR_TYPE_TO_SEARCH_FOR_CATALOG_ITEMS: 'Pilih atau ketik untuk mencari Item Katalog',
  ITEM_POSITION_IN_POPULAR_LIST: 'Posisi Barang dalam Daftar Barang Populer',
  ADD_TO_NEW_LIST: 'Tambahkan ke Daftar Baru',
  CANCEL: 'Batalkan',
  MANAGE_CATEGORY: 'Kelola Kategori',
  ITEM_POSITION_IN_NEW_LIST: 'Posisi Barang dalam Daftar Barang Baru',
  UPDATE_STATUS: 'Perbaraui Status',
  SHOW_ALL_ITEM: 'Tampilkan Semua Barang',
  VIEW_HISTORY: 'Lihat Riwayat',
  ITEM_NAME: 'Nama Barang',
  INITIAL_QTY: 'Qty Awal',
  ITEM_REEDEMED: 'Barang Ditukar',

  CREATE_NEW_ITEM : 'Buat Item Baru',
  NOT_SET : 'Belum Diatur',
  ITEM_POSITION_IN_CATALOG_LIST : 'Posisi Item di Daftar Katalog',
  CATALOG_ITEMS_DEFAULT_LOADING : 'Ini akan menjadi default saat memuat item katalog. Ketika pengguna mulai memfilter, ini tidak akan bertahan',
  ENTER_ITEM_NAME : 'Masukkan nama item',
  UNIQUE_PAGE_LINK : 'Ini adalah tautan halaman unik yang dihasilkan dari judul',
  UNIQUE_CATALOG_ITEM_LINK : 'Ini adalah tautan unik untuk item katalog',
  ITEM_SLUG : 'Slug Item',
  ITEM_CATEGORY : 'Kategori Item',
  NOTES_ITEM_CATEGORY : 'Catatan: Kategori item harus dipilih atau ketik untuk membuat kategori baru',
  ITEM_TYPE : 'Tipe Item',
  ITEM_PRICE : 'Harga Item',
  ITEM_QUOTA_STOCK : 'Kuota Item / Stok Item',
  ITEM_REDEEMED_BY_USER : 'Item yang Ditukarkan oleh Pengguna',
  AVAILABILITY : 'Ketersediaan',
  KEYWORDS : 'Kata Kunci',
  ITEM_DESCRIPTION : 'Deskripsi Item',
  ITEM_IMAGE : 'Gambar Item',
  ITEM_PUBLISH_DATE : 'Tanggal Publikasi Item',
  PRIZE_EXPIRE_DATE : 'Tanggal Kedaluwarsa Hadiah',
  HIDE_REDEEM_BUTTON : 'Sembunyikan Tombol Penukaran (Termasuk menonaktifkan proses penukaran)',
  SHOW_REWARD_BUTTON : 'Tampilkan Tombol Hadiah',
  CREATE_ITEM : 'Buat Item',
  CREATE_NEW_ITEM_AGAIN : 'Buat Item Baru',
  UPDATE_ITEM : 'Perbarui Item',
  WRITE_ABOUT_ITEM : 'Tulis sesuatu tentang item ini',
  UPLOAD_MULTIPLE_IMAGES : 'Unggah Beberapa Gambar',
  PREVIEW : 'Pratinjau',
  CREATED : 'Dibuat',
  MODIFIED : 'Dimodifikasi',
  UPDATE_ITEM_AGAIN : 'Perbarui Item',
  COMING_SOON_DATE : 'Tanggal Segera Hadir',
  UPDATE_CATALOG_ITEM : 'Perbarui Item Katalog',
  EDIT_ITEM : 'Edit Item',
  PREVIEW_ITEM : 'Pratinjau Item',
  PAUSE : 'Jeda',
  SELECT : 'Pilih',
  ENTER_MOLA_POINTS : 'Masukkan harga dalam Mola Points...',
  IMAGE_UPLOAD : 'URL Gambar atau klik untuk mengunggah',
  USE_THESE_IMAGE : 'Gunakan Gambar Ini',
  DELETE : 'Hapus',
  CONFIRM : 'Tolong, konfirmasi',
  DELETE_ITEM : 'Hapus Item',
  CHOOSE_STATUS: 'Pilih Status',

  CUSTOMER_INFO : 'Informasi Pelanggan',
  ITEM_DETAIL : 'Detail Item',
  ORDER_DATE : 'Tanggal Pesanan',
  VARIANT: 'Varian',
};

export default IDN;

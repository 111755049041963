const ENG = {
  //COMMON
  HOME: 'Home',
  BROADCAST_SCHEDULE: 'Broadcast Schedule',
  LEADERBOARD: 'Leaderboard',
  NEWS: 'News',
  CONTACT: 'Contact',
  LOGIN: 'Login',
  CLOSE: 'Close',
  FIGHT_WEEK: 'FIGHT WEEK ',
  LIVE_NOW: ' LIVE NOW',
  CONTINUE: 'Continue',
  USER_NAME: 'User Name',
  HOURS: 'hours',
  MIN: 'min',
  SIGN_OUT: 'SIGN OUT',
  MY_ACCOUNT: 'My Account',
  //HOME
  HOME_TITLE: 'PREDICT THE FIGHT <br> WIN BIG.',
  HOME_TAGLINE:
    'Guess the outcome of the fight and be in <br> with the chance to win grand prizes each week.',
  HOME_BUTTON: 'Predict Now',
  //BROADCAST
  BROADCAST_NOTES: 'Please, note that Prelims are in separate Tabs',
  BROADCAST_NOTES_MORE_EVENTS: 'Please, select an Event first.',
  BROADCAST_MAIN_CARD: 'Main Card',
  BROADCAST_PRELIMS: 'Prelims',
  BROADCAST_EARLY: 'Early Prelims',
  BROADCAST_MAIN_EVENT: 'Main Event',
  BROADCAST_POINTS_INFO: 'Points Info',
  BROADCAST_FIGHTER_RECORD: 'Fighter Record',
  BROADCAST_POINTS_ALLOCATION: 'Points allocation for this Event',
  BROADCAST_CORRECT_OUTCOME: 'Correct Outcome (Win, Draw):',
  BROADCAST_CORRECT_METHOD: 'Correct Method (KO/TKO etc.): ',
  BROADCAST_CORRECT_METHOD_SUBMISSION: 'Correct Method Submission: ',
  BROADCAST_CORRECT_METHOD_DECISION: 'Correct Method Decision: ',
  BROADCAST_BONUS: 'Bonus on Correct Outcome',
  BROADCAST_CORRECT_ROUND: 'Correct Nr. of Rounds: ',
  BROADCAST_MULTIPLIER_MAIN: 'Points Multiplier Main Card: ',
  BROADCAST_MULTIPLIER_PRELIMS: 'Points Multiplier Prelims: ',
  BROADCAST_MULTIPLIER_FEATURED: 'Points Multiplier Featured: ',
  BROADCAST_404: 'No predictions yet',
  BROADCAST_COUNTDOWN: 'Prediction submission will open on: ',
  BROADCAST_CLOSE_ON: 'Prediction submission closed on ',
  BROADCAST_AVAILABLE_SOON:
    'The Event has ended: The Leaderboard results will be available soon.',
  BROADCAST_END:
    'The Event has ended: The winning results can be seen on the Leaderboard.',
  BROADCAST_HOW: 'How?',
  BROADCAST_WHICH_ROUND: 'Which Round?',
  BROADCAST_CANCELLED: 'This fight was cancelled!',
  BROADCAST_LOGIN: 'Login to Make Predictions',
  BROADCAST_EVENT_404: 'There are currently no upcoming Events.',
  BROADCAST_STAY_TUNED: 'Stay tuned!',
  FIGHTER_PROFILE: 'Fighter Profile',
  FIGHTER_SEE_MORE: 'See more details about this fighter here',
  //RECAP
  RECAP_MON_RANK: 'Montlhy Rank',
  RECAP_MON_POINT: 'Monthly Points',
  RECAP_WEEK_RANK: 'Weekly Rank',
  RECAP_WEEK_POINT: 'Weekly Points',
  //AUTH
  AUTH_LOGIN: 'Login to Mola Digital - Catalog',
  AUTH_EMAIL: 'Email',
  AUTH_PASSWORD: 'Password',
  AUTH_ALREADY_HAVE_ACCOUNT: 'Already have an account? Login with password.',
  AUTH_BUTTON: 'Login / Sign Up',
  AUTH_CONTINUE_WITH_GOOGLE: 'Continue with Google',
  AUTH_CONFIRM_EMAIL: 'Please check your email!',
  AUTH_CONFIRM_EMAIL_2: 'Your confirmation link already sent to your email!',
  AUTH_EMAIL_VALIDATION: 'E-mail must be valid',
  AUTH_ONE_STEP_AHEAD: 'One Step Ahead',
  AUTH_INVALID_EMAIL: 'Invalid email link!',
  AUTH_WELCOME_BACK:
    '"Welcome Back! Redirecting you to the homepage in a few seconds..."',
  //LEADERBOARD
  LEADERBOARD_MONTHLY_LEADERBOARD: 'MONTHLY LEADERBOARD',
  LEADERBOARD_WEEKLY_LEADERBOARD: 'WEEKLY LEADERBOARD',
  LEADERBOARD_WEEKLY_LEADERBOARD_FUN: 'WEEKLY FUN LEADERBOARD',
  LEADERBOARD_MONTHLY_REWARD: 'MONTHLY REWARD LEADERBOARD',
  LEADERBOARD_WEEKLY_REWARD: 'WEEKLY REWARD LEADERBOARD',
  LEADERBOARD_MONTHLY: 'Monthly',
  LEADERBOARD_WEEKLY: 'Weekly',
  LEADERBOARD_MONTHLY_FUN: 'Monthly Fun',
  LEADERBOARD_WEEKLY_FUN: 'Weekly Fun',
  LEADERBOARD_REWARD_MONTHLY: 'Monthly Reward',
  LEADERBOARD_REWARD_WEEKLY: 'Weekly Reward',
  LEADERBOARD_RANK: 'Rank',
  LEADERBOARD_TOTAL_POINTS: 'Total Points',
  LEADERBOARD_TOTAL_MOLA_POINTS: 'Total Mola Points',
  LEADERBOARD_POINTS: 'Points',
  LEADERBOARD_CORRECT_OUTCOME: 'Correct Outcome',
  LEADERBOARD_CORRECT_METHOD: 'Correct Method',
  LEADERBOARD_CORRECT_ROUNDS: 'Correct Rouds',
  //PREDICTION
  PREDICTION: ' Prediction',
  PREDICTION_GAME: 'Game',
  PREDICTION_OUTCOME: 'Outcome',
  PREDICTION_METHOD: 'Method',
  PREDICTION_ROUNDS: 'Rounds',
  PREDICTION_SUBMISSION: 'Submission',
  PREDICTION_DECISION: 'Decision',
  PREDICTION_LOSES: 'Loses',
  PREDICTION_WIN: 'Win',
  PREDICTION_DRAW: 'Draw',
  YOUR_PREDICTION: 'Your Prediction',
  WHICH_ROUND: 'Which Round?',
  SUBMIT_PREDICTION: 'Submit Prediction',
  PREDICTION_CLOSE_IN: 'Prediction submission will close on ',
  IN_X_DAYS: ' in {x} days',
  //CONTACT
  CONTACT_US: 'Contact Us',
  CONTACT_MESSAGE:
    'Please don’t hesitate to contact us if you need further information or have other issues.',
  CONTACT_BUTTON: 'Send us an Email',
  //NEWS
  NEWS_MFP: 'Mola Fight Club News',
  NEWS_VIEWS: ' Views',
  NEWS_VIEW: ' Views',
  NEWS_SHARE: 'Share',
  NEWS_DAYS_AGO: ' Days Ago',
  //MY ACCOUNT HOME
  MY_ACCOUNT_WELCOME: 'Welcome',
  MY_ACCOUNT_MOLA_POINTS: 'Mola Points',
  MY_ACCOUNT_POINTS_WON: 'Weekly Points Won',
  MY_ACCOUNT_SWAP: 'Swap Requests',
  MY_ACCOUNT_PROFILE: 'My Profile',
  //PROFILE
  MY_PROFILE: 'My Profile',
  MY_PROFILE_ACCOUNT_VERIFICATION: 'Account Verification',
  MY_PROFILE_ACCOUNT_EMAIL: 'Account Email',
  MY_PROFILE_PASSWORD: 'Password',
  MY_PROFILE_ACCOUNT_INFORMATION: 'Account Information',
  MY_PROFILE_ACCOUNT_TYPE: 'Account Type',
  MY_PROFILE_ACCOUNT_STATUS: 'Account Status',
  MY_PROFILE_MOLA_SUBSCRIPTION: 'Mola Subscription',
  MY_PROFILE_ACTIVE: 'Active',
  MY_PROFILE_NOT_MOLA_SUBSCRIBER: 'Not a Mola Subscriber',
  MY_PROFILE_FREE: 'Free',
  MY_PROFILE_PERSONAL_INFORMATION: 'Personal Information',
  MY_PROFILE_DISPLAY_NAME: 'Display Name',
  MY_PROFILE_ABOUT_ME: 'About me',
  MY_PROFILE_FULL_LEGAL_NAME: 'Full Legal Name',
  MY_PROFILE_DOB: 'Date of Birth',
  MY_PROFILE_GENDER: 'Gender',
  MY_PROFILE_ADDRESS_DETAILS: 'Address Details',
  MY_PROFILE_LANGUAGE: 'Language',
  MY_PROFILE_CURRENT_VERSION: 'Current Version',
  MY_PROFILE_TANDC: 'Terms and Conditions',
  MY_PROFILE_PRIVACY_POLICY: 'Privacy Policy',
  MY_PROFILE_UPGRADE_NOW: 'Upgrade Now',
  MY_PROFILE_SUBSCRIBE_NOW: 'Subscribe Now',
  MY_PROFILE_SIGN_OUT: 'Sign Out from My Acccount',
  MY_PROFILE_BUTTON_CHANGE: 'Change',
  MY_PROFILE_ABOUT: '',
  MY_PROFILE_DOB_TEXT: 'Input your date of birth',
  MY_PROFILE_GENDER_NOT_SET: 'NOT SET',
  MY_PROFILE_GENDER_M: 'MALE',
  MY_PROFILE_GENDER_F: 'FEMALE',
  MY_PROFILE_GENDER_O: 'OTHERS',
  MY_PROFILE_PASSWORD_SET: 'Please, set your password',
  MY_PROFILE_NEW_EMAIL: 'Please, set your password',
  MY_PROFILE_CHECK_EMAIL: '',
  MY_PROFILE_EMAIL_INSTRUCTIONS: '',
  MY_PROFILE_BUT_CHANGE_EMAIL: 'Change Email',
  MY_PROFILE_ACC_NOT_VERIFIED: '',
  MY_PROFILE_ACC_VERIFIED: '',
  MY_PROFILE_ACC_BTN_VERIFY: '',
  MY_PROFILE_ACC_VERIFY: '',
  ACC_EMAIL_NOT_SET: 'Your email adress has not been set',
  ACC_EMAIL_ASS:
    'We have assigned you a temporary email address that is used for auhtentication together with your connected wallet.',
  // SETTINGS UPDATED
  SETTINGS_UPDATED_DISPLAYNAME: 'Display Name Updated',
  SETTINGS_UPDATED_ABOUTME: 'About Me Updated',
  SETTINGS_UPDATED_FULLNAME: 'Full Name Updated',
  SETTINGS_UPDATED_DOB: 'Date of Birth Updated',
  SETTINGS_UPDATED_GENDER: 'Gender Updated',
  SETTINGS_UPDATED_ADDRESS: 'Address Updated',
  SETTINGS_UPDATED_LANGUAGE: 'Language Updated',
  SETTINGS_UPDATED_PHOTO: 'Profile Photo Updated',
  //POINTS
  POINTS_NON_MOLA:
    'Because you are a free Mola subscriber, the points shown below are simulated points, that could have been won if you are a paid Mola subscriber',
  POINTS_FOR: 'Points Won for',
  POINTS_404: 'No results or points won for this events fights',
  POINTS_MORE: 'Load More from Past Events',
  POINTS_HISTORY: 'Points Won History',
  POINTS_HISTORY_404: 'No history of points won found',
  //ADMIN
  ADMIN_DASHBOARD: 'Admin Dashboard',
  GAME_MANAGEMENT: 'Game Management',
  ADMIN_MANAGEMENT: 'Admin Tasks',
  EVENTS_AND_GAMES: 'Events & Games',
  GAME_RESULTS: 'Game Results',
  CREATE_EVENT: 'Create Event',
  NEWS_MANAGEMENT: 'News Management',
  POINTS_AND_TOKEN_MANAGEMENT: 'Points & Token Management',
  POINTS_MANAGEMENT: 'Points Management',
  SWAP_REQUEST: 'Swap Request',
  MANAGE_EVENTS_AND_GAMES: 'Manage Events and Games',
  PROCESS_GAME_RESULTS: 'Process Game Results',
  CREATE_NEW_PREDICTION_GAME:
    'Create a New Prediction Game for Event and Fights',
  MANAGE_NEWS: 'Manage News',
  //DASHBOARD
  ADMIN_TOTAL_SIGN_UPS: 'Total Sign Ups',
  ADMIN_TOTAL_SIGN_UPS_TODAY: 'Total Sign Ups Today',
  ADMIN_TOTAL_SIGN_UPS_THIS_MONTH: 'Total Sign Ups This Month',
  ADMIN_TOTAL_MOLA_SUBSCRIBERS: 'Total Mola Subscribers',
  ADMIN_TOTAL_PREDICTIONS: 'Total Predictions',
  ADMIN_TOTAL_POINTS_WON: 'Total Points Won',
  //FOOTER
  FOOTER_TITLE: 'PREDICT THE FIGHT WIN BIG.',
  FOOTER_TITLE_NEW: 'SUBSCRIBE MOLA',
  MOLA_FIGHT_CLUB: 'Mola Digital - Catalog',
  ABOUT: 'About Mola Digital',
  SOCIAL: 'Social Networks',
  //Register
  REGISTER_WELCOME_TO_MFP: 'Welcome to Mola - Fight Predictor',
  REGISTER_LOGGED: 'You are logged in!',
  REGISTER_CONTINUE: 'Continue to complete your account creation',
  REGISTER_COMPLETE: 'Complete your Account',
  REGISTER_IN_ORDER:
    'In order to complete the setup of your account, we need a little more information from you',
  REGISTER_FULL_NAME: 'Full Name',
  REGISTER_PASSWORD: 'Password',
  REGISTER_SUBMIT: 'Submit',
  REGISTER_WELCOME: 'Welcome',
  REGISTER_WELCOME_BACK: 'Welcome Back',
  REGISTER_THANKS:
    'Thank you for joining us. You will be redirected to the hompage...',
  REGISTER_REDIRECT: 'Redirecting you to the homepage in a few seconds...',
  //Authorise
  LOADING: 'Loading...',
  ERROR: 'Error...',
  PLEASE_WAIT: 'Please wait in a few seconds...',
  BACK_TO_HOME: 'Back To Home',
  //
  PRODUCT_DESCRIPTION: 'Product Description',
  REDEEM_NOW: 'Redeem Now',
  QTY_LEFT: 'Quantity Left:',
  AVAILABLE: 'Stock',
  ORDER_STATUS: 'Order Status',
  NO_ORDERS_MESSAGE: "There is no order status",
  REDEEM_HISTORY: 'Redeem History',
  CATEGORY: 'Category',
  QTY: 'Quantity',
  REQUESTED: 'Requested',
  REJECTED: 'Rejected',
  COMPLETED: 'Completed',
  PROCESSED: 'Processed',
  PENDING: 'Pending',
  COMPLETE: 'Complete',
  ITEM: 'Item',
  TYPE: 'Type',
  POINTS: 'Points',
  DATE: 'Date',
  PRICE: 'Price',
  TOTAL: 'Total',
  MY_ITEM: 'My Item',
  EMPTY_REDEEM_HISTORY : 'Redeem History is empty!',
  ORDER_ID :'Order ID',
  CANCELLED : 'Cancelled',
  INVALID : 'Invalid',
  ON_DELIVERY : 'On Delivery',
  NO_POINT_FOUND: 'No Point History Found',
  STATUS : 'Status',
  WELCOME : 'Welcome',
  CATALOG : 'Catalog',
  SEARCH: 'Search',
  MY_ITEMS: 'My Items',
  REDEEM_FORM: 'Redeem Form',
  PRODUCT_NAME: 'Product Name',
  PRODUCT_PRICE: 'Product Price',
  USERNAME: 'Username',
  SINCE_THIS_IS_A_PHYSICAL_ITEM_PLEASE_PROVIDE_THE_SHIPPING_ADDRESS: 'Since this is a physical item, please provide the shipping address.',
  SHIPPING_DETAILS: 'Shipping Details:',
  RECEIVER_NAME_IF_DIFFERENT_FROM_ACCOUNT_NAME: 'Receiver name if different from account name',
  ADDRESS_LINE_1: 'Address Line 1',
  ADDRESS_LINE_2: 'Address Line 2',
  CITY: 'City',
  POSTAL_CODE: 'Postal Code',
  COUNTRY: 'Country',
  PHONE_NUMBER: 'Phone Number',
  SUBMIT_MY_ORDER: 'Submit My Order',
  ORDER_HAS_BEEN_SUBMITTED_SUCCESSFULLY: 'Order has been submitted successfully!',
  PRODUCT_TYPE: 'Product Type',
  NEW_ARRIVALS: 'New Arrivals',
  POPULAR_ITEMS: 'Popular Items',
  PHYSICAL: 'Physical',
  DIGITAL: 'Digital',
  SORT_BY: 'Sort by',
  FILTER_BY_POINTS: 'Filter by Points',
  SORT: 'Sort..',
  MINIMUM: 'Min',
  MAXIMUM: 'Max',
  MOLA_POINTS_LOW_TO_HIGH: 'Mola Points: Low to High',
  MOLA_POINTS_HIGH_TO_LOW: 'Mola Points: High to Low',
  NEWEST: 'Newest',
  OLDEST: 'Oldest',
  CLEAR_FILTER: 'Clear Filter',
  BACK_TO_MFP: 'Back to Mola Fight Club',
  MEMBER_SINCE: 'Member Since',
  PROFILE_SCORE: 'Profile Score',
  VERIFIED: 'Verified',
  NOT_VERIFIED: 'Not Verified',
  VERIFY: 'Verify',
  IMA: `I'm a `,
  STARTER: 'Starter',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  SUSPENDED: 'Suspended',
  SECONDS_AGO: ' seconds ago',
  MINUTES_AGO: ' minutes ago',
  HOURS_AGO: ' hours ago',
  DAYS_AGO: ' days ago',
  MONTHS_AGO: ' months ago',
  YEARS_AGO: ' years ago',
  BIRTHDAY: 'Birthday',
  LEGAL_NAME_TEXT: 'Add your full legal name here',
  PRODUCT_404: 'No Result Found',

  EMPTY: `{variable-name} can't be empty`,
  MINIMUM_LENGTH: `{variable-name} minimum length is {number}`,

  CHANGE_YOUR_PASSWORD: 'Change Your Password',
  NEW_PASSWORD: 'New Password',
  CURRENT_PASSWORD: 'Current Password',
  SET_NEW_PASSWORD: 'Set new Password',
  CLICK_TO_SELECT_A_PHOTO: 'Click to select a photo',
  CHANGE_PHOTO: 'Change Photo',
  REMOVE_PHOTO: 'Remove Photo',
  SAVE: 'Save',
  SET_PICTURE: 'Set Picture',
  TAP_TO_SELECT_PHOTO: 'Tap here to select a photo <br>from your gallery',

  APPLY: 'Apply',
  FILTER: 'Filter',
  COMING_SOON: 'Coming Soon',
  REWARD: 'Reward',
  ROWS_PER_PAGE: 'Rows per page', 
  ROWS_PER_PAGE_OF: 'of',
  ROWS_PER_PAGE_ALL: 'All',

  MANAGE_ORDER: 'Manage Order',
  ORDER_MANAGEMENT: 'Order Management',
  MANAGE_CATALOG: 'Manage Catalog',
  TRACKING_DASHBOARD: 'Tracking Dashboard',
  ADMIN_TASK: 'Admin Task',
  SIDE_MENU_FAVORITES: 'Side Menu Favorites',
  MANAGE_SIDE_MENU_TITLE: 'Manage Side Menu Popular Items and New Arrivals',
  EMPTY_LIST: 'This list is empty',
  ADD: 'Add',
  DOWNLOAD_EXCEL: 'Download Excel',
  UPDATE_ORDER_DETAIL: 'Update Order Detail',
  ORDER_DETAIL: 'Order Detail',
  ACTION: 'Action',
  SHOW_PUBLISHED_ITEM: 'Show Published Item Only',
  CATEGORY_NAME: 'Category Name',
  LANGUAGE_CONFIGURATION : 'Language Configuration',
  SHOW_ALL_STATUS : 'Show All Status',
  DRAFT : 'Draft',
  APPROVED : 'Approved',
  PUBLISHED : 'Published',
  PAUSED : 'Paused',
  EXPIRED : 'Expired',
  SHOW_ALL_CATEGORIES : 'Show All Categories',
  TYPE_TO_SEARCH_OR_FILTER : 'Type to Search or Filter',
  LOAD_MORE : 'Load More',
  SHOWING_LAST_ITEMS : 'Showing Last ({x}) items',
  EXPIRES : 'Expires',
  AVAILABLES : 'Availables',
  TOTAL_ITEM : 'Total Item',
  TOTAL_ORDER : 'Total Order',
  TOTAL_POINT_SPEND : 'Total Point Spend',
  TOTAL_USERS : 'Total Users',
  TOTAL_COMPLETED_ORDER : 'Total Completed Order',
  TOTAL_CATEGORY : 'Total Category',
  REDEMPTION_RATE : 'Redemption Rate',
  REMAINING_STOCK : 'Remaining Stock',
  ADD_TO_POPULAR_LIST : 'Add To Popular List',
  SELECT_OR_TYPE_TO_SEARCH_FOR_CATALOG_ITEMS : 'Select or type to search for Catalog Items',
  ITEM_POSITION_IN_POPULAR_LIST : 'Item Position in Popular List',
  ADD_TO_NEW_LIST : 'Add to New List',
  CANCEL: 'Cancel',
  MANAGE_CATEGORY: 'Manage Category',
  ITEM_POSITION_IN_NEW_LIST: 'Item Position in New List',
  UPDATE_STATUS: 'Update Status',
  SHOW_ALL_ITEM: 'Show All Items',
  VIEW_HISTORY: 'View History',
  ITEM_NAME: 'Item Name',
  INITIAL_QTY: 'Initial Quantity',
  ITEM_REEDEMED: 'Item Reedemed',
  
  CREATE_NEW_ITEM : 'Create New Item',
  NOT_SET : 'Not Set',
  ITEM_POSITION_IN_CATALOG_LIST : 'Item Position in Catalog List',
  CATALOG_ITEMS_DEFAULT_LOADING : 'This will be the default on loading the catalog items. When user start filtering this will not persist',
  ENTER_ITEM_NAME : 'Enter the item name',
  UNIQUE_PAGE_LINK : 'This is the unique page link generated from the title',
  UNIQUE_CATALOG_ITEM_LINK : 'This is the unique link for the catalog item',
  ITEM_SLUG : 'Item Slug',
  ITEM_CATEGORY : 'Item Category',
  NOTES_ITEM_CATEGORY : 'Notes: Item category should be selected or type to create new category',
  ITEM_TYPE : 'Item Type',
  ITEM_PRICE : 'Item Price',
  ITEM_QUOTA_STOCK : 'Item Quota / Item Stock',
  ITEM_REDEEMED_BY_USER : 'Item Reedemed By User',
  AVAILABILITY : 'Availability',
  KEYWORDS : 'Keywords',
  ITEM_DESCRIPTION : 'Item Description',
  ITEM_IMAGE : 'Item Image',
  ITEM_PUBLISH_DATE : 'Item Publish Date',
  PRIZE_EXPIRE_DATE : 'Prize Expire Date',
  HIDE_REDEEM_BUTTON : 'Hide Reedem Button (Including disable reedem process)',
  SHOW_REWARD_BUTTON : 'Show Reward Button',
  CREATE_ITEM : 'Create Item',
  CREATE_NEW_ITEM_AGAIN : 'Create New Item',
  UPDATE_ITEM : 'Update Item',
  WRITE_ABOUT_ITEM : 'Write something about this item',
  UPLOAD_MULTIPLE_IMAGES : 'Upload Multiple Images',
  PREVIEW : 'Preview',
  CREATED : 'Created',
  MODIFIED : 'Modified',
  UPDATE_ITEM_AGAIN : 'Update Item',
  COMING_SOON_DATE : 'Coming Soon Date',
  UPDATE_CATALOG_ITEM : 'Update Catalog Item',
  EDIT_ITEM : 'Edit Item',
  PREVIEW_ITEM : 'Preview Item',
  PAUSE : 'Pause',
  SELECT: 'Select',
  ENTER_MOLA_POINTS: 'Enter price in Mola Points...',
  IMAGE_UPLOAD: 'Image URL or click to upload',
  USE_THESE_IMAGE: 'Use these Images',
  DELETE: 'Delete',
  CONFIRM: 'Please, confirm',
  DELETE_ITEM: 'Delete Item',

  CHOOSE_STATUS: 'Choose Status',
  CUSTOMER_INFO: 'Customer Information',
  ITEM_DETAIL: 'Item Details',
  ORDER_DATE: 'Order Date',
  VARIANT: 'Variant',
};

export default ENG;

import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/authorise/:source?',
    name: 'Authorise',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Authorise.vue'),
  },
  {
    path: '/auth/:email/:token',
    name: 'Authorise',
    component: () =>
      import(/* webpackChunkName: "about" */ '../components/login/Authenticate.vue'),
  },

  // ########################################################################################
  // ##################################### App User Views ###################################
  // ########################################################################################
  {
    path: '/myaccount',
    name: 'myaccount',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/users/MyAccHome.vue'),
  },
  {
    path: '/myprofile',
    name: 'myprofile',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/users/MyProfile.vue'),
  },
  {
    path: '/myitems',
    name: 'My Items',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/users/MyItem.vue'
      ),
  },
  {
    path: '/catalog/:slug',
    name: 'Catalog Item',
    meta: {
      requiresAuth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/users/Catalog.vue'
      ),
  },
  // ###########################################################################################
  // ################################### Admin Console Routes ##################################
  // ###########################################################################################
  {
    path: '/admin',
    name: 'Admin Console',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/AdminHome.vue'),
  },
  {
    path: '/admin/login',
    name: 'Admin Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/Login.vue'),
  },
  {
    path: '/admin/catalog',
    name: 'Admin Manage Catalog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/Catalog.vue'),
  },
  {
    path: '/admin/category',
    name: 'Admin Manage Category',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/Category.vue'),
  },
  {
    path: '/admin/tracking',
    name: 'Admin Tracking Order',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/TrackingDashboard.vue'),
  },
  {
    path: '/admin/order',
    name: 'Admin Order',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/Order.vue'),
  },
  {
    path: '/admin/favorites',
    name: 'Admin Favorites',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/admin/Favorites.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    console.log(to, from, savedPosition)
    return { top: 0 }
  },
});

export default router;

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const FIREBASE_CONFIG_TEST_ENV_UK = {
    apiKey: "AIzaSyBJGYXvFIjEG9tvtE5OHjnq2MbYc6EdC9o",
    authDomain: "mola-catalog-staging-uk.firebaseapp.com",
    projectId: "mola-catalog-staging-uk",
    storageBucket: "mola-catalog-staging-uk.appspot.com",
    messagingSenderId: "516004837949",
    databaseURL: "https://mola-catalog-staging-uk.firebaseio.com",
    appId: "1:516004837949:web:c43167fb6c3a537a7f8854",
    //measurementId: "G-12ZZ1LYSXR"
  };
  
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const FIREBASE_CONFIG_PROD_ENV_UK = {
    apiKey: "AIzaSyB-ZKJL1ocaK3I-00VpK4nQD5EzpkGRzak",
    authDomain: "mola-catalog-production-uk.firebaseapp.com",
    projectId: "mola-catalog-production-uk",
    storageBucket: "mola-catalog-production-uk.appspot.com",
    messagingSenderId: "837784174389",
    databaseURL: "https://mola-catalog-production-uk.firebaseio.com",
    appId: "1:837784174389:web:8ecd025465ca18e306d67c",
  };
  
  var FIREBASE_CONFIG_UK = {};
  
  if (process.env.VUE_APP_ENVIRONMENT === "production") {
    console.log("PROD ENVIRONMENT");
    FIREBASE_CONFIG_UK = FIREBASE_CONFIG_PROD_ENV_UK;
  } else if (process.env.VUE_APP_ENVIRONMENT === "staging") {
    console.log("STAGING ENVIRONMENT");
    FIREBASE_CONFIG_UK = FIREBASE_CONFIG_TEST_ENV_UK;
  } else {
    console.log("LOCAL ENVIRONMENT");
    FIREBASE_CONFIG_UK = FIREBASE_CONFIG_TEST_ENV_UK;
  }
  
  export default FIREBASE_CONFIG_UK;
  
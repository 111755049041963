import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import store from "@/store/index";
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';
import 'vuetify/dist/vuetify.min.css';
import ItalyIcon from '../components/icons/ItalyIcon.vue'
import IndonesiaIcon from '../components/icons/IndonesiaIcon.vue';
import EnglishIcon from '../components/icons/EnglishIcon.vue';

const vuetify = new Vuetify({
  // computed: {
  //     getDark () {
  //         return this.$store.state.user.dark;
  //     }
  // },
  theme: {
    themes: {
      light: {
        //primary: '#F2F6F9',
        primary_blue: '#F2F6F9',
        primary: '#C40621',
        secondary: '#F8DF8D',
      },
      dark: {
        // primary: '#C40621',
        primary: '#C40621',
        primary_blue: '#C40621',
        secondary: '#F8DF8D',
        accent: '#8F8F8F',
      },
    },
  },
  icons: {
    values: {
      italy: {
        component: ItalyIcon,
      },
      indonesia: {
        component: IndonesiaIcon,
      },
      english: {
        component: EnglishIcon,
      },
    },
  },
});

Vue.use(Vuetify);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi',
});

export default vuetify;

<template>
  <div id="home" :style="dark ? 'background-color: #000;' : 'background-color: #FFF;'">
    <v-slide-y-transition mode="out-in">
      <v-layout column>
        <!-- ####################### SKELETON LOADERS ####################### -->
        <v-layout
          column
          align-center
          v-if="loading"
          :style="windowWidth < 770 ? 'margin-top:30px' : 'margin-top:200px;'"
        >
          <p class="mt-2"></p>

          <v-progress-circular
            :size="70"
            :width="7"
            color="deep-purple"
            indeterminate
          ></v-progress-circular>
        </v-layout>


        <!-- ############################################## COMPONENTS  ##############################################  -->
        <v-layout column v-else>
          <v-layout id="home" column>
            <v-card
              flat
              :style="dark ? 'background-color: #000;' : 'background-color: #FFF;height:100%'"
              style="border-radius:0px"
              :dark="dark"
              :width="'100%'"
              :min-height="windowHeight"
            >
              <v-toolbar
                height="70"
                color="#C40621"
              >
              <div class="white--text text-h5 font-weight-bold">{{lang[getLanguage].CATALOG}}</div>
              <span class="mx-2 text-h5 white--text" v-if="!$vuetify.breakpoint.mobile">|</span>
              <span v-if="!$vuetify.breakpoint.mobile">
                <v-chip
                  :class="'mr-2'"
                  color="white"
                  style="color:#000"
                  :small="drawer"
                  :outlined="choosenCategory !== item.name"
                  pill
                  v-for="(item, index) in category"
                  @click.stop="categoryClicked(item.name)"
                  :key="index"
                >
                  <v-icon left v-if="item.icon.includes('mdi')">
                    {{item.icon}}
                  </v-icon>
                  {{ ![undefined, null].includes(item.lang) ? item.lang[getLanguage].toUpperCase() : item.name.toUpperCase() }}
                </v-chip>
              </span>
              <v-spacer></v-spacer>
              <v-row class="">
                <v-col cols="12" class="d-flex justify-end">
                <v-text-field
                    solo
                    light
                    hide-details
                    :style="drawer ? 'max-width:200px' : 'max-width:400px'"
                    :label="lang[getLanguage].SEARCH"
                    v-model="searchModel"
                    @keyup="startSearch"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                </v-col>
              </v-row>
              </v-toolbar>

              <!-- Catalog Items searchable-->
              <v-row class="mx-auto">
                <v-col cols="12" md="2" v-if="!$vuetify.breakpoint.mobile">
                    <SideFilter :dark=dark />
                </v-col>
                <v-col cols="12" :md="!$vuetify.breakpoint.mobile ? 10 : 12">
                  <Shop class="" :dark=dark :drawer="drawer" :isMobileDevice="isMobileDevice" :windowWidth="windowWidth"/>
                </v-col>
              </v-row>
            </v-card>
          </v-layout>
          

        </v-layout>
      </v-layout>
    </v-slide-y-transition>
    
  </div>
</template>

<script>
import Shop from '../components/catalog/Shop.vue';
import SideFilter from '../components/catalog/SideFilter.vue';

// @ is an alias to /src
export default {
  name: 'Home',
  props: {
    isMobileDevice: Boolean,
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    drawer: Boolean
  },
  data: () => ({
    loading: false,
    choosenCategory: '',
    searchModel: '',
    min: null,
    max: null,
  }),
  components: {
    Shop,
    SideFilter
  },
  computed: {
    getUser() {
      return this.$store.state.user;
    },
    lang(){
      return this.$store.state.lang;
    },
    getLanguage(){
      return this.$store.state.current_language
    },
    category(){
      return this.$store.state.catalog.categories;
    },
    catalogs(){
      return this.$store.state.catalog.itemCatalog;
    },
    search(){
      return this.$store.state.catalog.search;
    },
    filter(){
      return this.$store.state.catalog.filter;
    },
    filterCategory () {
      return this.$store.state.catalog.filterCategory
    },
    sortFilter(){
      return this.$store.state.catalog.sortFilter;
    },
    isReload(){
      console.log(this.$store.state.isReload);
      return this.$store.state.isReload;
    },
  },
  watch: {
    search () {
      this.searchModel = this.search
    }
  },
  created() {
    // this.currentUser = firebase.auth().currentUser;
    // this.init();
    // console.log('filter', this.filter);
    // console.log('filterCategory', this.filterCategory);
    this.choosenCategory = this.filter;
    this.$store.dispatch("getCategories");
    console.log('this.search !== this.searchModel || this.search !== ""')
    console.log(this.search)
    if (this.search !== this.searchModel || this.search !== '') {
      this.$store.commit('setState', {filter: this.choosenCategory})
      this.$store.dispatch("getCatalogs", {
        limit: 2000,
        search: this.search,
        filter: '',
        lastVisible: null,
        lastVisibleFrom: '',
        next: false
      });
    }

    console.log('Reload: ', this.isReload);
    if(this.isReload){
      this.$store.commit('setState', {filter: ''})
      this.$store.dispatch("getCatalogs", {
        sortFilter: 0,
        limit: 2000,
        search: this.search,
        filter: '',
        lastVisible: null,
        lastVisibleFrom: '',
        next: false,
        reload: true
      });
      this.$store.commit('setIsReload', {isReload: false});
    }
    if (this.filterCategory !== '') {
      this.choosenCategory = this.filterCategory
    }
    this.searchModel = this.search
  },
  methods: {
    alert(value){
      console.log(value);
    },
    loginClicked () {
      this.$emit("loginClicked")
    },
    startSearch(){
      if (this.searchModel.length < 3 && this.searchModel.length !== 0) return;
      this.$store.dispatch("getCatalogs", {
        limit: 2000,
        search: this.searchModel.toLowerCase(),
        filter: this.choosenCategory,
        lastVisible: null,
        lastVisibleFrom: '',
        next: false,
        sortFilter: this.sortFilter
      });
    },
    categoryClicked(item){
      this.choosenCategory = this.choosenCategory === item ? '' : item;
      if(this.choosenCategory !== ''){
        this.$store.dispatch("getCatalogs", {
          limit: 2000,
          search: '',
          filter: this.choosenCategory,
          lastVisible: null,
          lastVisibleFrom: '',
          next: false,
          sortFilter: this.sortFilter
        });
      } else{
        this.$store.dispatch("getCatalogs", {reload: false, filter: '', sortFilter: this.sortFilter});
      }
    }
  }
}
</script>

<style scoped>

  .homeTitleNew {
    font-size: 50px;
    font-weight: 600;
    line-height: 60px;
  }

.card-color{
   background-color: #07207ba9 !important;
   border: 2px solid rgba(4, 31, 111, 0.488) !important;
}

.filterLink{
  cursor: pointer;
}

.filterLink:hover{
  color:#07207ba9;
}
</style>

import { db } from '../main';
import MolaMfpAPI from '../clients/MolaMfpAPI';
import firebase from 'firebase/app';

export default {
  state: {
    orderHistory: [],
    redeemHistory: [],
    orders: [],
    completedOrders: []
  },
  mutations: {
    setState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    async getOrderByUid({ commit }, payload) {
      let arrOrder = [];
      let query = db.collection('order').where('uid', '==', payload).orderBy('order_date', 'desc');
      const snapshot = await query.get();
      snapshot.forEach((doc) => {
        const obj = doc.data();
        obj.id = doc.id;
        arrOrder.push(obj);
      });
      commit('setState', {
        orderHistory: arrOrder,
      });
    },
    async getRedeemHistory({ commit }, payload) {
      const result = await MolaMfpAPI.getRedeemHistory(payload);
      console.log(result.data);
      result.data.data.forEach(item => {
        item.type_text = item.type_text === 'Redeem Points Product Debit' ? 'Menukarkan Poin Debit Produk' : item.type_text;
      })
      commit('setState', {
        redeemHistory: result.data.data,
      });
    },
    async getOrders({ commit }) {
      let arrOrder = [];
      let query = db.collection('order').orderBy('order_date', 'desc');
      const snapshot = await query.get();
      snapshot.forEach((doc) => {
        const obj = doc.data();
        obj.id = doc.id;
        arrOrder.push(obj);
      });
      console.log(arrOrder)
      commit('setState', {
        orders: arrOrder,
      });
    },
    async getCompletedOrders({ commit }, payload) {
      console.log(payload)
      let arrOrder = [];
      let query = db
        .collection('order')
        .where('uid', '==', payload)
        .where('status', '==', 3);
        try{
          const snapshot = await query.get();
          snapshot.forEach((doc) => {
            const obj = doc.data();
            obj.id = doc.id;
            arrOrder.push(obj);
          });
          commit('setState', {
            completedOrders: arrOrder,
          });
        }catch(err){
          console.log(err);
        }
      
    },
    async changeOrderStatus({ commit, state }, payload) {
      try {
        await db.collection('order').doc(payload.order_id).update(
          {
            status: payload.status,
          },
          { merge: true }
        );

        if (payload.status === 1 || payload.status === 9) {
          let newMfpStatus = payload.status === 9 ? 3 : 1;
          await MolaMfpAPI.changeStatus({
            order_id: payload.order_id,
            status: newMfpStatus, // 1:completed, 2:pending, 3:cancelled)
          });
        }

        //Reject / Cancel
        if(payload.status === 9){
          console.log('changeOrderStatus', payload.variant);
          if([null, undefined, ''].includes(payload.variant)){
            await db.collection('catalog').doc(payload.item_id).update(
              {
                redeemed: firebase.firestore.FieldValue.increment(payload.qty * - 1),
                item_quantity_left: firebase.firestore.FieldValue.increment(payload.qty * 1),
              }
            );
          }

          if(![null, undefined, ''].includes(payload.variant)){
            const catalog = await db.collection('catalog').doc(payload.item_id).get()
            let variants = catalog.data().variants;
            variants = variants.map(variant => {
              if (variant.label === payload.variant) {
                return { ...variant, redeemed: variant.redeemed - payload.qty }; // Modify as needed
              }
              return variant;
            });
            console.log(variants);
            await db.collection('catalog').doc(payload.item_id).update(
              { 
                variants: variants,
                redeemed: firebase.firestore.FieldValue.increment(payload.qty * - 1),
                item_quantity_left: firebase.firestore.FieldValue.increment(payload.qty * 1),
              }
            );
          }
        }

        let arrOrder = state.orders;
        let index = arrOrder.findIndex((x) => x.id === payload.order_id);
        arrOrder[index].status = payload.status;
        commit('setState', {
          orders: arrOrder,
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
  getters: {},
};

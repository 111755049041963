<template>
  <v-row justify="center" class="mx-auto">
    <v-dialog
      v-model="dialog"
      :width="!isMobileDevice ? '600px' : ''"
      :fullscreen="isMobileDevice"
    >
      <v-card class="mx-auto" :dark="dark">
        <v-card-title
          v-if="!isMobileDevice"
          class="text-h6 font-weight-regular justify-center"
          style="background-color: #C40621; color: #fff"
        >
          <span>{{ currentTitle }}</span>
        </v-card-title>
        <v-toolbar v-else style="background-color: #C40621; color: #fff">
          <v-card-title class="font-weight-regular">
            <span style="font-size: 1rem"
              ><span>{{ currentTitle }}</span></span
            >
          </v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon :dark="dark" @click.native="dialog = false" color="#FFF">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-window v-model="step">
          <v-window-item :value="1">
            <div class="pa-4 text-center">
              <v-img
                class="mb-4"
                contain
                height="128"
                :src="dark ? '/img/logos/superlive_logo.png' : '/img/logos/superlive_logo.png'"
              ></v-img>
              <h3 class="text-h6 font-weight-light mb-2">
                <v-icon 
                left
                :color="dark ? 'white' : 'blue'"
                >mdi-email</v-icon> info@moladigital.com
              </h3>
              <span class="text-caption grey--text"
                >{{ lang[getLanguage].CONTACT_MESSAGE }}</span
              >
            </div>
          </v-window-item>
        </v-window>

        <v-divider></v-divider>

        <v-card-actions class="my-0 flex-column">
          <v-btn
            style="color: #fff"
            class="my-2"
            v-show="step === 1"
            color="#C40621"
            block
            large
            depressed
            @click="gotoLink('mailto:info@moladigital.com')"
          >
            {{ lang[getLanguage].CONTACT_BUTTON }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    isOpen: Boolean,
    isMobileDevice: Boolean,
    dark: Boolean,
  },
  data: () => ({
    step: 1,
  }),
  created() {
  },
  computed: {
    theme() {
      console.log(this.$vuetify.theme.dark);
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
    dialog: {
      get() {
        return this.isOpen;
      },
      set(newValue) {
        this.$emit('change', newValue);
      },
    },
    lang(){
      return this.$store.state.lang;
    },
    getLanguage(){
      return this.$store.state.current_language
    },
    currentTitle() {
      return this.lang[this.getLanguage].CONTACT_US
    },
  },
  methods: {
    gotoLink(link) {
      if (this.isMobileDevice) {
        window.location.href = link
      } else {
        window.open(link, "_blank");
      } 
    }
  },
};
</script>

import MolaMfpAPI from '../clients/MolaMfpAPI';

export default {
  state: {
    total_user: 0,
    total_point_spend: 0,
    total_order: 0,
    total_complete_order: 0,
    total_category: 0,
    total_item: 0
  },
  mutations: {
    setState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    async getAdminDashboard({ commit }) {
      let res;
      const today = new Date();
      const cache =
        localStorage.getItem('mola-catalog-admin-dashboard') === null
          ? null
          : JSON.parse(localStorage.getItem('mola-catalog-admin-dashboard'));
      console.log('cache', cache);
      if (cache !== null) {
        if (cache.expired >= today.getTime()) {
          commit('setState', {
            total_user: cache.total_user,
            total_point_spend: cache.total_point_spend,
            total_order: cache.total_order,
            total_complete_order: cache.total_complete_order,
            total_category: cache.total_category,
            total_item: cache.total_item,
          });
          return;
        }
        else{
          localStorage.removeItem('mola-catalog-admin-dashboard');
        }
      }
      
      try {
        const response = await MolaMfpAPI.getAdminDashboard();
        res = response;
      } catch (err) {
        console.log(err);
        res = null;
      }
      console.log(res);
      if (res && res.status === 200) {
        const expiredDate = new Date()
        const payload = {
          total_user: res.data.total_user,
          total_point_spend: res.data.total_point_spend,
          total_order: res.data.total_order,
          total_complete_order: res.data.total_complete_order,
          total_category: res.data.total_category,
          total_item: res.data.total_item,
          expired: expiredDate.getTime() + (3600 * 1000)
        };
        localStorage.setItem('mola-catalog-admin-dashboard', JSON.stringify(payload));
        commit('setState', payload);
      } else {
        commit('setState', {
          total_user: 0,
          total_point_spend: 0,
          total_order: 0,
          total_complete_order: 0,
          total_category: 0,
          total_item: 0
        });
        localStorage.removeItem('mola-catalog-admin-dashboard');
      }
    },
  },
  getters: {},
};

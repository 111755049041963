const ITA = {
  //COMMON
  HOME: 'Home',
  BROADCAST_SCHEDULE: 'Programma',
  LEADERBOARD: 'Classifica',
  NEWS: 'Notizie',
  CONTACT: 'Contatto',
  LOGIN: 'Accedi',
  CLOSE: 'Chiudi',
  FIGHT_WEEK: 'FIGHT WEEK ',
  LIVE_NOW: ' LIVE ORA',
  CONTINUE: 'Continua',
  USER_NAME: 'Nome Utente',
  HOURS: 'ore',
  MIN: 'minuti',
  SIGN_OUT: 'DISCONNESSIONE',
  MY_ACCOUNT: 'Il mio account',
  //HOME
  HOME_TITLE: 'FAI IL TUO PRONOSTICO <br> E VINCI.',
  HOME_TAGLINE: `Indovina l'esito del match e partecipa per avere la possibilità <br> di vincere grandi premi ogni settimana.`,
  HOME_BUTTON: 'Fai il tuo pronostico ora.',
  //BROADCAST
  BROADCAST_NOTES: 'Nota bene che la Card Preliminare è in una scheda separata',
  BROADCAST_NOTES_MORE_EVENTS: 'Per favore, seleziona prima un evento.',
  BROADCAST_MAIN_CARD: 'Main Card',
  BROADCAST_PRELIMS: 'Prelims',
  BROADCAST_EARLY: 'Early Prelims',
  BROADCAST_MAIN_EVENT: 'Main Event',
  BROADCAST_POINTS_INFO: 'Informazioni sui punti',
  BROADCAST_FIGHTER_RECORD: 'Record del fighter',
  BROADCAST_POINTS_ALLOCATION: 'Assegnazione di punti per questo evento',
  BROADCAST_CORRECT_OUTCOME: 'Esito corretto (vittoria, pareggio): ',
  BROADCAST_CORRECT_METHOD: 'Metodo corretto (KO/TKO eccetera.): ',
  BROADCAST_CORRECT_METHOD_SUBMISSION: 'Metodo corretto sottomissione: ',
  BROADCAST_CORRECT_METHOD_DECISION: 'Metodo corretto decisione: ',
  BROADCAST_BONUS: 'Bonus sul risultato corretto',
  BROADCAST_CORRECT_ROUND: 'Numero corretto di round: ',
  BROADCAST_MULTIPLIER_MAIN: 'Moltiplicatore di punti Main Card: ',
  BROADCAST_MULTIPLIER_PRELIMS: 'Moltiplicatore di punti Card Preliminare: ',
  BROADCAST_MULTIPLIER_FEATURED: 'Moltiplicatore di punti in evidenza: ',
  BROADCAST_404: 'Da pronosticare',
  BROADCAST_COUNTDOWN: 'Potrai fare i pronostici a partire dal ',
  BROADCAST_CLOSE_ON: 'Prediction submission closed on ',
  BROADCAST_AVAILABLE_SOON: `L'evento è terminato: i risultati della classifica saranno disponibili a breve.`,
  BROADCAST_END: `L'evento è terminato: i risultati vincenti possono essere visti nella classifica.`,
  BROADCAST_HOW: 'Come?',
  BROADCAST_WHICH_ROUND: 'Quale giro?',
  BROADCAST_CANCELLED: 'Questa lotta è stata annullata!',
  BROADCAST_LOGIN: 'Accedi per fare una previsione',
  BROADCAST_EVENT_404: 'Al momento non ci sono eventi imminenti.',
  BROADCAST_STAY_TUNED: 'Rimani sintonizzato!',
  FIGHTER_PROFILE: 'Profilo del Fighter',
  FIGHTER_SEE_MORE: 'Vedi qui altri dettagli su questo fighter',
  //RECAP
  RECAP_MON_RANK: 'Classifica Mensile',
  RECAP_MON_POINT: 'Punti Mensili',
  RECAP_WEEK_RANK: 'Classifica Settimanale',
  RECAP_WEEK_POINT: 'Classifica Settimanale',
  //AUTH
  AUTH_LOGIN: 'Accedi a Mola - Fight Predictor',
  AUTH_EMAIL: 'Email',
  AUTH_PASSWORD: 'Password',
  AUTH_ALREADY_HAVE_ACCOUNT: 'Hai già un account? Accedi con la tua password.',
  AUTH_BUTTON: 'Login / Iscriviti',
  AUTH_CONTINUE_WITH_GOOGLE: 'Continua con Google',
  AUTH_CONFIRM_EMAIL: 'Controlla la tua email!',
  AUTH_CONFIRM_EMAIL_2:
    'Il link di conferma è stato già inviato alla tua email!',
  AUTH_EMAIL_VALIDATION: `L'e-mail deve essere valida`,
  AUTH_ONE_STEP_AHEAD: 'Un passo avanti',
  AUTH_INVALID_EMAIL: 'Link email non valido!',
  AUTH_WELCOME_BACK:
    '"Welcome Back! Ti reindirizzeremo alla pagina iniziale in pochi secondi..."',
  //LEADERBOARD
  LEADERBOARD_MONTHLY_LEADERBOARD: 'Classifica mensile',
  LEADERBOARD_WEEKLY_LEADERBOARD: 'Classifica settimanale',
  LEADERBOARD_WEEKLY_LEADERBOARD_FUN: 'Classifica settimanale FUN',
  LEADERBOARD_MONTHLY: 'Mensile',
  LEADERBOARD_WEEKLY: 'Settimanale',
  LEADERBOARD_MONTHLY_FUN: 'Mensile Fun',
  LEADERBOARD_WEEKLY_FUN: 'Settimanale Fun',
  LEADERBOARD_REWARD_MONTHLY: 'Mensile Reward',
  LEADERBOARD_REWARD_WEEKLY: 'Settimanale Reward',
  LEADERBOARD_RANK: 'Classifica',
  LEADERBOARD_TOTAL_POINTS: 'Punti totali',
  LEADERBOARD_TOTAL_MOLA_POINTS: 'Punti Mola Totali',
  LEADERBOARD_POINTS: 'Punti',
  LEADERBOARD_CORRECT_OUTCOME: 'Esito corretto',
  LEADERBOARD_CORRECT_METHOD: 'Metodo corretto',
  LEADERBOARD_CORRECT_ROUNDS: 'Round corretti',
  //PREDICTION
  PREDICTION: ' PRONOSTICO',
  PREDICTION_GAME: 'Gioco',
  PREDICTION_OUTCOME: 'Esito',
  PREDICTION_METHOD: 'Metodo',
  PREDICTION_ROUNDS: 'Round',
  PREDICTION_SUBMISSION: 'Sottomissione',
  PREDICTION_DECISION: 'Decisione',
  PREDICTION_LOSES: 'Sconfitta',
  PREDICTION_WIN: 'Vittoria',
  PREDICTION_DRAW: 'Pareggio',
  YOUR_PREDICTION: 'La Tua Previsione',
  WHICH_ROUND: 'Quale round?',
  SUBMIT_PREDICTION: 'Fai la tua previsione',
  PREDICTION_CLOSE_IN: 'Ricordati di fare la tua previsione entro il ',
  IN_X_DAYS: ' {x} giorno prima',
  //CONTACT
  CONTACT_US: 'Contattaci',
  CONTACT_MESSAGE:
    'Non esitate a contattarci se avete bisogno di ulteriori informazioni o in caso di problemi.',
  CONTACT_BUTTON: 'INVIA UNA MAIL',
  //NEWS
  NEWS_MFP: 'Notizie del Mola Fight Club',
  NEWS_VIEWS: ' Visualizzazioni',
  NEWS_VIEW: ' Visualizzazioni',
  NEWS_SHARE: 'Condividi',
  NEWS_DAYS_AGO: ' giorni fa',
  //MY ACCOUNT HOME
  MY_ACCOUNT_WELCOME: 'Benvenuto',
  MY_ACCOUNT_MOLA_POINTS: 'Mola Punti',
  MY_ACCOUNT_POINTS_WON: 'Punti vinti',
  MY_ACCOUNT_SWAP: 'Richieste di scambio',
  MY_ACCOUNT_PROFILE: 'Il mio profilo',
  //PROFILE
  MY_PROFILE: 'Il mio profilo',
  MY_PROFILE_ACCOUNT_VERIFICATION: `Verifica dell'account`,
  MY_PROFILE_ACCOUNT_EMAIL: 'Email Account',
  MY_PROFILE_PASSWORD: 'Password',
  MY_PROFILE_ACCOUNT_INFORMATION: 'Informazioni account',
  MY_PROFILE_ACCOUNT_TYPE: 'Tipo di account',
  MY_PROFILE_ACCOUNT_STATUS: `Stato dell'account`,
  MY_PROFILE_MOLA_SUBSCRIPTION: 'Iscrizione a Mola',
  MY_PROFILE_ACTIVE: 'Attivo',
  MY_PROFILE_NOT_MOLA_SUBSCRIBER: 'Non abbonato Mola',
  MY_PROFILE_FREE: 'Gratuito',
  MY_PROFILE_PERSONAL_INFORMATION: 'Informazioni personali',
  MY_PROFILE_DISPLAY_NAME: 'Nome utente',
  MY_PROFILE_ABOUT_ME: 'Informazioni su di me',
  MY_PROFILE_FULL_LEGAL_NAME: 'Nome completo',
  MY_PROFILE_DOB: 'Data di nascita',
  MY_PROFILE_GENDER: 'Genere',
  MY_PROFILE_ADDRESS_DETAILS: 'Dettagli indirizzo',
  MY_PROFILE_LANGUAGE: 'Lingua',
  MY_PROFILE_CURRENT_VERSION: 'Versione attuale',
  MY_PROFILE_TANDC: 'Termini e condizioni',
  MY_PROFILE_PRIVACY_POLICY: 'Informativa sulla privacy',
  MY_PROFILE_UPGRADE_NOW: 'Aggiorna ora',
  MY_PROFILE_SUBSCRIBE_NOW: 'Iscriviti ora',
  MY_PROFILE_SIGN_OUT: 'Esci dal mio account',
  MY_PROFILE_BUTTON_CHANGE: 'Modifica',
  MY_PROFILE_ABOUT: '',
  MY_PROFILE_DOB_TEXT: 'Inserisci la tua data di nascita',
  MY_PROFILE_GENDER_NOT_SET: 'NON IMPOSTATO',
  MY_PROFILE_GENDER_M: 'MASCHIO',
  MY_PROFILE_GENDER_F: 'FEMMINA',
  MY_PROFILE_GENDER_O: 'OTHERS',
  MY_PROFILE_PASSWORD_SET: 'Please, set your password',
  MY_PROFILE_NEW_EMAIL: 'Please, set your password',
  MY_PROFILE_CHECK_EMAIL: '',
  MY_PROFILE_EMAIL_INSTRUCTIONS: '',
  MY_PROFILE_BUT_CHANGE_EMAIL: 'Modifica Email',
  MY_PROFILE_ACC_NOT_VERIFIED: '',
  MY_PROFILE_ACC_VERIFIED: '',
  MY_PROFILE_ACC_BTN_VERIFY: '',
  MY_PROFILE_ACC_VERIFY: '',
  ACC_EMAIL_NOT_SET: 'Your email adress has not been set',
  ACC_EMAIL_ASS:
    'We have assigned you a temporary email address that is used for auhtentication together with your connected wallet.',
  // SETTINGS UPDATED
  SETTINGS_UPDATED_DISPLAYNAME: 'Nome utente aggiornato',
  SETTINGS_UPDATED_ABOUTME: 'Informazioni su di me aggiornate',
  SETTINGS_UPDATED_FULLNAME: 'Nome completo aggiornato',
  SETTINGS_UPDATED_DOB: 'Data di nascita aggiornata',
  SETTINGS_UPDATED_GENDER: 'Genere aggiornato',
  SETTINGS_UPDATED_ADDRESS: 'Indirizzo aggiornato',
  SETTINGS_UPDATED_LANGUAGE: 'Lingua aggiornata',
  SETTINGS_UPDATED_PHOTO: 'Foto del profilo aggiornata',
  //POINTS
  POINTS_NON_MOLA:
    'Poiché sei un abbonato Mola gratuito, i punti mostrati di seguito sono punti simulati che avresti potuto vincere se tu fossi stato un abbonato Mola a pagamento.',
  POINTS_FOR: 'Punti vinti per',
  POINTS_404:
    'Nessun risultato o punto vinto per i combattimenti di questo evento',
  POINTS_MORE: 'Carica altro dagli eventi passati',
  POINTS_HISTORY: 'Points Won History',
  POINTS_HISTORY_404: 'No history of points won found',
  //ADMIN
  ADMIN_DASHBOARD: `Pannello dell'Admin`,
  GAME_MANAGEMENT: 'Gestione del gioco',
  ADMIN_MANAGEMENT: 'Admin Tasks',
  EVENTS_AND_GAMES: 'Eventi e giochi',
  GAME_RESULTS: 'Risultati del gioco',
  CREATE_EVENT: 'Crea evento',
  NEWS_MANAGEMENT: 'Gestione delle notizie',
  POINTS_AND_TOKEN_MANAGEMENT: 'Gestione punti e token',
  POINTS_MANAGEMENT: 'Gestione dei punti',
  SWAP_REQUEST: 'Richiesta di scambio',
  MANAGE_EVENTS_AND_GAMES: 'Gestisci eventi e giochi',
  PROCESS_GAME_RESULTS: 'Elabora i risultati del gioco',
  CREATE_NEW_PREDICTION_GAME: 'Crea un nuovo pronostico per eventi e match',
  MANAGE_NEWS: 'Gestisci le notizie',
  //DASHBOARD
  ADMIN_TOTAL_SIGN_UPS: 'Totale Iscrizioni',
  ADMIN_TOTAL_SIGN_UPS_TODAY: 'Totale Iscrizioni di oggi',
  ADMIN_TOTAL_SIGN_UPS_THIS_MONTH: 'Totale Iscrizioni di questo mese',
  ADMIN_TOTAL_MOLA_SUBSCRIBERS: 'Totale Abbonati Mola',
  ADMIN_TOTAL_PREDICTIONS: 'Totale dei pronostici',
  ADMIN_TOTAL_POINTS_WON: 'Totale Punti Vinti',
  //FOOTER
  FOOTER_TITLE: 'FAI IL TUO PRONOSTICO E VINCI.',
  FOOTER_TITLE_NEW: 'ABBONATI A MOLA',
  MOLA_FIGHT_CLUB: 'Mola Digital - Catalogo',
  ABOUT: 'Informazioni su Mola Digital',
  SOCIAL: 'Social Networks',
  //Register
  REGISTER_WELCOME_TO_MFP: 'Benvenuti a Mola - Fight Predictor',
  REGISTER_LOGGED: 'You are logged in!',
  REGISTER_CONTINUE: 'Continua per completare la creazione del tuo account',
  REGISTER_COMPLETE: 'Completa il tuo Account',
  REGISTER_IN_ORDER:
    'Per completare la configurazione del tuo account, abbiamo bisogno di più informazioni da te.',
  REGISTER_FULL_NAME: 'Nome completo',
  REGISTER_PASSWORD: 'Password',
  REGISTER_SUBMIT: 'Invia',
  REGISTER_WELCOME: 'Benvenuto',
  REGISTER_WELCOME_BACK: 'Welcome Back',
  REGISTER_THANKS:
    'Grazie per esserti unito a noi. Sarai reindirizzato alla pagina iniziale....',
  REGISTER_REDIRECT: 'Reindirizzamento alla home page in pochi secondi...',
  //Authorise
  LOADING: 'Caricamento...',
  ERROR: 'Errore...',
  PLEASE_WAIT: 'Attendere tra qualche secondo...',
  BACK_TO_HOME: 'Tornare a casa',
  //
  PRODUCT_DESCRIPTION: 'Descrizione Prodotto',
  REDEEM_NOW: 'Riscatta Ora',
  QTY_LEFT: 'Quantità Rimasta:',
  AVAILABLE: 'Azione',
  ORDER_STATUS: 'Stato Ordine',
  NO_ORDERS_MESSAGE: "Non esiste uno stato dell'ordine",
  REDEEM_HISTORY: 'Storico Riscatti',
  CATEGORY: 'Categoria',
  QTY: 'Quantità',
  REQUESTED: 'Richiesto',
  REJECTED: 'Respinto',
  COMPLETED: 'Completato',
  PROCESSED: 'Elaborato',
  PENDING: 'In Attesa',
  COMPLETE: 'Completa',
  ITEM: 'Articolo',
  TYPE: 'Tipo',
  POINTS: 'Punti',
  DATE: 'Data',
  PRICE: 'Prezzo',
  TOTAL: 'Totale',
  MY_ITEM: 'Il Mio Articolo',
  EMPTY_REDEEM_HISTORY: 'La Storico di Riscatti è vuoto!',
  ORDER_ID: 'ID Ordine',
  CANCELLED: 'Annullato',
  INVALID: 'Non Valido',
  ON_DELIVERY: 'In Consegna',
  NO_POINT_FOUND: 'Nessuna Storico Punti Trovato',
  STATUS: 'Stato',
  WELCOME: 'Benvenuto',
  CATALOG: 'Catalogo',
  SEARCH: 'Cerca',
  MY_ITEMS: 'I Miei Articoli',
  REDEEM_FORM: 'Modulo di Riscatto',
  PRODUCT_NAME: 'Nome Prodotto',
  PRODUCT_PRICE: 'Prezzo Prodotto',
  USERNAME: 'Nome Utente',
  SINCE_THIS_IS_A_PHYSICAL_ITEM_PLEASE_PROVIDE_THE_SHIPPING_ADDRESS: `Poiché si tratta di un articolo fisico, fornire l'indirizzo di spedizione.`,
  SHIPPING_DETAILS: 'Dettagli di Spedizione:',
  RECEIVER_NAME_IF_DIFFERENT_FROM_ACCOUNT_NAME: `Nome Ricevente se diverso dal nome dell'account`,
  ADDRESS_LINE_1: 'Indirizzo Linea 1',
  ADDRESS_LINE_2: 'Indirizzo Linea 2',
  CITY: 'Città',
  POSTAL_CODE: 'Codice Postale',
  COUNTRY: 'Paese',
  PHONE_NUMBER: 'Numero di Telefono',
  SUBMIT_MY_ORDER: 'Invia Il Mio Ordine',
  ORDER_HAS_BEEN_SUBMITTED_SUCCESSFULLY: `L'ordine è stato inviato con successo!`,
  PRODUCT_TYPE: 'Tipo Prodotto',
  NEW_ARRIVALS: 'Nuovi Arrivi',
  POPULAR_ITEMS: 'Articoli Popolari',
  PHYSICAL: 'Fisico',
  DIGITAL: 'Digitale',
  SORT_BY: 'Ordina per',
  FILTER_BY_POINTS: 'Filtra per Punti',
  SORT: 'Ordina',
  MINIMUM: 'Min',
  MAXIMUM: 'Max',
  MOLA_POINTS_LOW_TO_HIGH: 'Punti Mola: Bassi a Alti',
  MOLA_POINTS_HIGH_TO_LOW: 'Punti Mola: Alti a Bassi',
  NEWEST: 'Più Nuovi',
  OLDEST: 'Più Vecchi',
  CLEAR_FILTER: 'Rimuovi filtro',
  BACK_TO_MFP: 'Torna a Mola Fight Club',
  MEMBER_SINCE: 'Membro dal',
  PROFILE_SCORE: 'Punteggio profilo',
  VERIFIED: 'Verificato',
  NOT_VERIFIED: 'Non verificato',
  VERIFY: 'Verifica',
  IMA: 'Sono un ',
  STARTER: 'Antipasto',
  ACTIVE: 'Attivo',
  INACTIVE: 'Inattivo',
  SUSPENDED: 'Sospeso',
  SECONDS_AGO: ' secondi fa',
  MINUTES_AGO: ' minuti fa',
  HOURS_AGO: ' ore fa',
  DAYS_AGO: " giorni fa",
  MONTHS_AGO: ' mesi fa',
  YEARS_AGO: ' anni fa',
  COMPLEANNO: 'Compleanno',
  LEGAL_NAME_TEXT: 'Aggiungi il tuo nome completo',
  PRODUCT_404: 'Nessun risultato trovato',

  EMPTY: `{variable-name} non può essere vuoto`,
  MINIMUM_LENGTH: `la lunghezza minima di {variable-name} è {number}`,

  CHANGE_YOUR_PASSWORD: 'Cambia la tua password',
  NEW_PASSWORD: 'Nuova password',
  CURRENT_PASSWORD: 'Password attuale',
  SET_NEW_PASSWORD: 'Imposta nuova password',
  CLICK_TO_SELECT_A_PHOTO: 'Clicca per selezionare una foto',
  CHANGE_PHOTO: 'Cambia foto',
  REMOVE_PHOTO: 'Rimuovi foto',
  SAVE: 'Salva',
  SET_PICTURE: 'Imposta Immagine',
  TAP_TO_SELECT_PHOTO: 'Tocca qui per selezionare una foto <br>dalla tua galleria',

  APPLY: 'Applicare',
  FILTER: 'Filtro',
  COMING_SOON: 'Prossimamente',
  REWARD: 'Ricompensa'

};

export default ITA;
<template>
  <v-card :dark="darkMode" class="mx-md-8 mx-0 px-0">
    <div class=""
    >
        <v-skeleton-loader
          type="image"
        >
        </v-skeleton-loader>
        <br/>

        <v-skeleton-loader
          class="mx-4"
          type="chip"
        >
        </v-skeleton-loader>
        <br/>
        <v-skeleton-loader
          type="list-item-three-line"
        >
        </v-skeleton-loader>
        <br/>
        <v-skeleton-loader
          type="card-heading"
        >
        </v-skeleton-loader>
        <br/>
        <v-skeleton-loader
          type="card-heading"
        >
        </v-skeleton-loader>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    darkMode: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.my-card {
  border-radius: 10px;
  overflow: hidden;
}

.v-gradient-overlay {
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.8));
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.v-card-title {
  font-size: 2rem;
  font-weight: 600;
  margin-top: 1rem;
}

.v-icon {
  margin-bottom: 0.5rem;
}
</style>
